<template>
  <section-place-info
    :business="business"
    :place="place"
    :title="translations.contactPlaceInfoMainTitle"
    scroll-id="placeMainCity"
  />
</template>

<script>
  import SectionPlaceInfo from '@/views/sections/reusable/PlaceInfo'
  import { latLng } from 'leaflet'
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'SectionContactPlaceInfoMain',
    components: {
      SectionPlaceInfo,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      departments () {
        return this.translations?.contactSectionsItems?.map(item => ({
          icon: item.icon,
          ...item,
        }))
      },
      business () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            text: this.translations?.contactPlaceInfoMainLocale,
          },
          {
            icon: 'mdi-cellphone',
            text: documentToHtmlString(this.translations?.contactPlaceInfoMainPhones),
          },
          {
            icon: 'mdi-email',
            text: this.translations?.contactPlaceInfoMainEmail,
            scrollId: 'sections',
          },
          {
            icon: 'mdi-chart-line',
            text: documentToHtmlString(this.translations?.contactPlaceInfoMainSales),
          },
          {
            icon: 'mdi-briefcase',
            text: documentToHtmlString(this.translations?.contactPlaceInfoMainFinancial),
          },
          {
            icon: 'mdi-account',
            text: documentToHtmlString(this.translations?.contactPlaceInfoMainPersonal),
          },
        ]
      },
    },
    data: () => ({
      place:
        {
          geo: latLng(50.043430, 19.275210),
          address: 'ul. Lipowa 5 \b 32-600 Oświęcim',
        },
    }),
  }
</script>
